/* eslint-disable react/no-danger, react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import SEO from '@/components/Seo'
import { cleanProps } from '@/utils'
import Blocks from '@/utils/Blocks'
import useConstant from '@/hooks/useConstant'
/* eslint-disable no-unused-vars */
import { pageQuery } from '@/queries'
/* eslint-enable no-unused-vars */
import * as T from '@/types'

function ResearchProgram({
	pageContext: { media, wordpress_id },
	data: {
		page: { blocks, yoast, featured_media }
	}
}) {
	const data = useConstant(() => cleanProps(blocks, media))
	const form = data.pop()

	return (
		<>
			<SEO {...yoast} image={featured_media} />
			<Blocks data={data} media={media} currentPage={wordpress_id} />

			<div className="mb-20 wrapper md:mb-24 lg:mb-32">
				<h2 className="mb-8 leading-snug text-h2-fluid font-museoSans">
					Global impact. <br />
					We’re working with researchers worldwide.
				</h2>
				<img
					src="/images/map.png"
					alt="World map"
					width="1919"
					height="887"
					loading="lazy"
				/>
			</div>

			<Blocks data={[form]} media={media} currentPage={wordpress_id} />
		</>
	)
}

ResearchProgram.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired
	})
}

export const query = graphql`
	query ResearchPageQuery($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}
	}
`

export default ResearchProgram
